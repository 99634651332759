import React, { useState, useEffect } from 'react';
import { SketchPicker } from 'react-color';

// Utility to recursively find colors in the Lottie JSON
const findColorsInObject = (obj, path, colors) => {
    if (typeof obj === 'object' && obj !== null) {
        for (const key in obj) {
            const newPath = path.concat(key);
            if (key === 'c' && obj[key].a === 0 && Array.isArray(obj[key].k)) {
                const color = obj[key].k.slice(0, 3).map(c => Math.round(c * 255)); // Convert to RGB
                colors.push({ path: newPath, color: `rgb(${color.join(', ')})` });
            } else {
                findColorsInObject(obj[key], newPath, colors);
            }
        }
    }
};

function ColorEditorComponent({ jsonData, onUpdateJson }) {
    const [colors, setColors] = useState([]);

    // Extract colors from JSON
    const extractColors = (data) => {
        let foundColors = [];
        findColorsInObject(data, [], foundColors);
        setColors(foundColors);
    };

    // Update colors in JSON
    const updateColor = (color, colorPath) => {
        const newData = JSON.parse(JSON.stringify(jsonData)); // Deep clone
        let obj = newData;
        for (let i = 0; i < colorPath.length - 1; i++) {
            obj = obj[colorPath[i]];
        }
        obj[colorPath[colorPath.length - 1]].k = [
            color.rgb.r / 255,
            color.rgb.g / 255,
            color.rgb.b / 255,
            1 // Assuming full opacity
        ];
        onUpdateJson(newData);
    };

    // Initially extract colors when component mounts or json changes
    useEffect(() => {
        if (jsonData) {
            extractColors(jsonData);
        }
    }, [jsonData]);

    return (
        <div className="flex flex-wrap">
            {colors.map((colorInfo, index) => (
                <div key={index} className="p-2">
                    <h3>{`Color ${index + 1}`}</h3>
                    <SketchPicker
                        color={colorInfo.color}
                        onChangeComplete={color => updateColor(color, colorInfo.path)}
                    />
                </div>
            ))}
        </div>
    );
}

export default ColorEditorComponent;
