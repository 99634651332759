import React from 'react';

function UploadComponent({ onJsonLoaded }) {
    const handleFileChange = event => {
        const fileReader = new FileReader();
        if (event.target.files[0]) {
            fileReader.readAsText(event.target.files[0], "UTF-8");
            fileReader.onload = e => {
                try {
                    const json = JSON.parse(e.target.result);
                    onJsonLoaded(json);
                } catch (error) {
                    console.error("Error parsing JSON:", error);
                }
            };
        }
    };

    return (
        <input type="file" onChange={handleFileChange} className="my-4 p-2 border rounded-lg" />
    );
}

export default UploadComponent;
