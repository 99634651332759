import React from 'react';
import { saveAs } from 'file-saver';

function SaveComponent({ jsonData }) {
    const handleSave = () => {
        // Create a Blob from the JSON string
        const blob = new Blob([JSON.stringify(jsonData, null, 2)], { type: 'application/json;charset=utf-8' });
        saveAs(blob, 'modified-animation.json');  // Trigger the file download
    };

    return (
        <button
            className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={handleSave}
        >
            Save & Download JSON
        </button>
    );
}

export default SaveComponent;
