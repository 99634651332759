import React from 'react';

function AnimationSpeedComponent({ speed, onSpeedChange }) {
    return (
        <div className="my-4">
            <label htmlFor="speed-control" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Animation Speed ({speed})</label>
            <input
                id="speed-control"
                type="range"
                min="0.1"
                max="3"
                step="0.1"
                value={speed}
                onChange={(e) => onSpeedChange(parseFloat(e.target.value))}
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
            />
        </div>
    );
}

export default AnimationSpeedComponent;
