import React, { useState } from 'react';
import UploadComponent from './UploadComponent';
import ColorEditorComponent from './ColorEditorComponent';
import AnimationSpeedComponent from './AnimationSpeedComponent';
import LottiePlayer from './LottiePlayer';
import SaveComponent from './SaveComponent';

function App() {
  const [jsonData, setJsonData] = useState(null);
  const [animationSpeed, setAnimationSpeed] = useState(1);

  return (
      <div className="App bg-linear-gray min-h-screen pt-12">
          <header className="text-center">
              <h1 className="text-3xl font-bold text-linear-dark mb-6">Lottie Editor</h1>
              <UploadComponent onJsonLoaded={setJsonData} />
              {jsonData && (
                  <>
                      <LottiePlayer animationData={jsonData} speed={animationSpeed} />
                      <ColorEditorComponent jsonData={jsonData} onUpdateJson={setJsonData} />
                      <AnimationSpeedComponent speed={animationSpeed} onSpeedChange={setAnimationSpeed} />
                      <SaveComponent jsonData={jsonData} />
                  </>
              )}
          </header>
      </div>
  );
}


export default App;
